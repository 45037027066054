.price-editor-table {
  border-spacing: 5px;
}

/* .regular-price-row:hover,
.cost-price-row:hover {
  background-color: azure
} */

.price-row-even {
  /* background-color: #eee; */
}

.price-row-odd {
  background-color: #f7f7f7;
}

.input-field {
  border: 1px solid lightgray;
  padding: 5px;
  border-radius: 4px
}

.input-field.input-field-price {
  width: 100px;
}

.ddl {
  border: 1px solid lightgray;
  padding: 4px;
  border-radius: 4px
}

.cost-price-row .input-field-ro {
  background-color: cornsilk;
  /* border: 1px solid lightgray; */
}

.reset-btn:enabled {
  background-color: deeppink;
  color: white;
}

.discount-row.discount-row-editing {
  background-color: beige;
}

.discount-description {
  background-color: beige;
}

.discount-editform-buttons {
  border: 2px solid red;
  padding: 5px;
  /* display: inline-block; */
}